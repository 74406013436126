<template>
  <el-scrollbar y view-style="padding: 0 15px 15px">
    <div class="g-bg g-p-20">
      <el-form :model="searchForm" inline>
        <el-form-item label="审核状态">
          <el-select v-model="searchForm.examine_status" clearable>
            <el-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员名称">
          <el-input v-model="searchForm.user_name" placeholder="请输入人员名称"></el-input>
        </el-form-item>
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="searchForm.date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onRefresh">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData">
        <el-table-column label="姓名" prop="user.name"></el-table-column>
        <el-table-column label="所在部门" prop="department.name"></el-table-column>
        <el-table-column label="内容" prop="content" show-overflow-tooltip=""></el-table-column>
        <el-table-column label="时间" prop="schedule_date" width="160px"></el-table-column>
        <el-table-column label="审核状态" prop="examine_status" width="160px">
          <template slot-scope="{ row }">
            <div>
              {{ row.examine_status | dict('reportAuditStatusDict') }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="{ row }">
            <c-btn-table
              :show="['info', 'update', 'delete']"
              @info="onInfo(row)"
              @update="onUpdate(row)"
              @delete="onDelete(row)"
            >
              <el-button v-if="row.examine_status === 'not_examine'" type="primary" @click="onAudit(row)"
                >审核</el-button
              >
            </c-btn-table>
          </template>
        </el-table-column>
      </el-table>

      <b-pagination :page="page" @change="fetchList"></b-pagination>
    </div>

    <b-dialog ref="detailDialog" width="800px">
      <detail-view :data="formData" @success="onDetailSuccess"></detail-view>
    </b-dialog>

    <b-dialog ref="infoDialog" width="700px">
      <info-view :row="currentRow" @success="onSuccess"></info-view>
    </b-dialog>
  </el-scrollbar>
</template>

<script>
import DetailView from '../components/detail.vue'
import InfoView from '../components/audit-info.vue'
import { getAllReportPage, deleteReport, updateReport } from '@/api/report/index'
import { reportAuditStatus } from '@/config/dict'

export default {
  components: {
    DetailView,
    InfoView,
  },
  data() {
    return {
      statusList: reportAuditStatus,
      searchForm: {
        user_name: '',
        examine_status: 'pass',
        date: [],
      },
      tableData: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      formData: {},
      currentRow: {},
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      console.log(this.searchForm)
      const params = {
        page: this.page.current,
      }
      if (this.searchForm.examine_status) {
        params.examine_status = this.searchForm.examine_status
      }
      if (this.searchForm.user_name) {
        params.user_name = this.searchForm.user_name
      }
      if (this.searchForm.date.length) {
        params.start_date = this.searchForm.date[0]
        params.end_date = this.searchForm.date[1]
      }

      getAllReportPage(params).then(res => {
        this.tableData = res.data
        this.page.total = res.total
      })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onDetailSuccess() {
      this.onRefresh()
      this.$refs.detailDialog.close()
    },
    onSuccess() {
      this.onRefresh()
      this.$refs.detailDialog.close()
      this.$refs.infoDialog.close()
    },
    onCreate() {
      this.formData = {
        department_id: '',
        user_id: '',
        schedule_date: '',
        content: '',
      }
      this.$refs.detailDialog.open()
    },
    onInfo(row) {
      this.currentRow = this.$tools.copy(row)
      this.$refs.infoDialog.open()
    },
    onUpdate(row) {
      this.formData = {
        id: row.id,
        department_id: row.department_id,
        user_id: row.user_id,
        schedule_date: row.schedule_date,
        content: row.content,
      }
      this.$refs.detailDialog.open()
    },
    onDelete(row) {
      this.$confirm('确定要删除当前项吗', { type: 'warning' })
        .then(() => {
          return deleteReport(row.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefresh()
        })
        .catch(() => {})
    },
    onAudit(row) {
      this.$confirm('确认通过吗？', { type: 'warning', title: '提示' })
        .then(() => {
          updateReport(row.id, { examine_status: 'pass' }).then(res => {
            this.$message.success('通过成功')
            this.onRefresh()
          })
        })
        .catch(() => {})
    },
  },
}
</script>
